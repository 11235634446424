import {
    EventEmitter
} from "events";

let baseUrl = "https://api.coingecko.com/api/v3/simple/price?ids=";

class PriceConfig extends EventEmitter {


    constructor() {
        super();
        this.ethPrice = 0;
        this.bnbPrice = 0;
    }

    
    async fetchEthPrice(){
        let url = baseUrl+"ethereum&vs_currencies=usd";
        const response = await fetch(url);
        const json = await response.json();
        this.ethPrice = json.ethereum.usd;
    }

    async fetchBnbPrice(){
        let url = baseUrl+"binancecoin&vs_currencies=usd";
        const response = await fetch(url);
        const json = await response.json();
        this.bnbPrice = json.binancecoin.usd;
    }


    getEthprice(){
        return this.ethPrice;
    } 


    getBnbprice(){
        return this.bnbPrice;
    } 


    handleActions(action) {
        switch (action.type) { }
    }


}

const priceConfig = new PriceConfig();
export default priceConfig;
