import {
    EventEmitter
} from "events";
import web3Js from 'web3';
import { ethers } from 'ethers';

import ethAbi from "../abis/eth.json";

import constantConfig from "../config/constantConfig";


class EthIcoContract extends EventEmitter {

    constructor(web3,networkId) {
        super();
        this.web3 = web3;
        this.networkId = networkId;
        this.icoAddress = constantConfig.ethAddress;
        this.icoInstance = new ethers.Contract(
            this.icoAddress,
            ethAbi,
            web3.getSigner(0)
        );
        
    }

    



    async tokenRequest(optionId,value,txCb,receiptCb){
        let a = await this.icoInstance.populateTransaction.tokenRequest(optionId);
        value = (Number(value)+Number(0.003)).toString();
        value = web3Js.utils.toWei(value);
        this.sendTransaction(a.data,value,"635285",txCb,receiptCb,this.icoAddress);
    }


    async sendTransaction(payload,value,gasLimit,txCb,receiptCb,to){
        let gasPrice = "0";
        
        const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json');
        const json = await response.json();
        gasPrice = (json.fast/10).toString();
    
        const tx = {
            to: to, 
            data: payload, 
            gasPrice: web3Js.utils.toHex(web3Js.utils.toWei(gasPrice,"gwei")), 
            gasLimit:web3Js.utils.toHex(gasLimit),
            value: web3Js.utils.toHex(value)
        };

        this.web3.getSigner(0).sendTransaction(tx).then(result => {
            txCb(result.hash)
            result.wait().then(async (receipt)=>{
                receiptCb(receipt);
            })
        }).catch(error => {
            console.log(error);
        });
    }

    

    


    handleActions(action) { 
        switch (action.type) { }
    }

}





export default EthIcoContract;