import React from "react";
import web3Config from "../config/web3Config";
import constantConfig from "../config/constantConfig";
import Countdown from "react-countdown";
import validation from "../helper/validation";
import IcoContract from "../helper/IcoContract";
import EthIcoContract from "../helper/ethContract";
import Slider from "react-slick";
import Loader from "react-loader-spinner";
import web3Js from 'web3';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useAlert } from 'react-alert'
import useOutsideClick from "../helper/useOutsideClick";
import priceConfig from "../helper/pricing";
import ReactTooltip from "react-tooltip";


const { useState, useEffect, useRef } = React;




export default function IcoBox(props) {

    const alert = useAlert()

    const { data } = props;

    const ref = useRef();

    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [address, setAddress] = useState(web3Config.getAddress());
    const [networkId, setNetworkId] = useState(web3Config.getNetworkId());
    const [soldOut, setSoldOut] = useState(0);
    const [avilableToken, setAvilableToken] = useState(0);
    const [totalRaised, setTotalRaised] = useState(0);
    const [investValue, setInvestValue] = useState('');
    const [tcValue, setTcValue] = useState('');
    const [investLoading, setinvestLoading] = useState(false);
    const [claimLoading, setclaimLoading] = useState(false);
    const [icoContract, setIcoContract] = useState(null);
    const [tcOpen, setTcOpen] = useState(false);
    const [tokenPrice, setTokenPrice] = useState(false);

    const [bnb, setBnb] = useState(0);
    const [userInvestments, setUserInvestments] = useState([]);
    const [userOrderId, setUserOrderId] = useState([]);
    const [userTotalInvestment, setUserTotalInvestment] = useState(0);

    const [currOpen, setCurrOpen] = useState(false);

    IcoContract.getSoldOut(data.OptionId, (a, b) => {
        setSoldOut(a);
        setTotalRaised(b);
    });

    IcoContract.getTokenAmount(data.OptionId, (a) => {
        setAvilableToken(a)
    });

    function updateData() {


        if (!constantConfig.allowedNetwork.includes(web3Config.getNetworkId())) {
            return
        }

        setAddress(web3Config.getAddress());
        setNetworkId(web3Config.getNetworkId());

        let icoContract_ = new IcoContract(web3Config.getWeb3(), web3Config.getNetworkId());
        setIcoContract(icoContract_);

        web3Config.getNativeBalance(web3Config.getAddress(), (response) => {
            setBnb(response - 0.00733096);
        })

        icoContract_.getUserOrderOptionWise(web3Config.getAddress(), data.OptionId, (a, b) => {
            setUserInvestments(a);
            setUserOrderId(b);
        });

        icoContract_.getInvestOptionWise(web3Config.getAddress(), data.OptionId, (a) => {
            setUserTotalInvestment(a);
        });

    }

    useOutsideClick(ref, () => {
        setCurrOpen(false)
    });

    useEffect(async () => {

        web3Config.on("WEB3_CONNECTED", updateData);

        IcoContract.getSoldOut(data.OptionId, (a, b) => {
            setSoldOut(a);
            setTotalRaised(b);
        });

        const response = await fetch(data.priceApi);
        const json = await response.json();
        console.log(json)
        setTokenPrice(json.usd)

    }, [])

    const renderer = ({ days, hours, minutes, seconds, milliseconds, completed }) => {
        if (completed) {
            // Render a completed state
            return < > PreSale Ended </>
        } else {
            const d = days < 10 ? "0" + days.toString() : days.toString()
            const h = hours < 10 ? "0" + hours.toString() : hours.toString()
            const m = minutes < 10 ? "0" + minutes.toString() : minutes.toString()
            const s = seconds < 10 ? "0" + seconds.toString() : seconds.toString()
            const ms = milliseconds < 10 ? "0" + milliseconds.toString() : milliseconds.toString()

            return (
                <div>
                    <span >{d[0]}</span>
                    <span >{d[1]}</span>:
                    <span >{h[0]}</span>
                    <span >{h[1]}</span>:
                    <span >{m[0]}</span>
                    <span >{m[1]}</span>:
                    <span >{s[0]}</span>
                    <span >{s[1]}</span>:
                    <span >{ms[0]}</span>
                    <span >{ms[1]}</span>
                </div>

            )
        }
    }


    let style = { display: "block" };
    if (!isDetailOpen) style.display = "none";

    const addDefaultSrc = (ev) => {
        ev.target.src = require('../assets/images/alt.png')
    }

    function invest() {
        if (!reactLocalStorage.get(data.tokenAddress)) {
            setTcOpen(true);
            return;
        }
        if (investValue === '' || investValue === 0) {
            alert.show("Please Enter Valid value");
            return;
        }

        let nativePrice = (networkId === 97 || networkId === 56) ? priceConfig.getBnbprice() : priceConfig.getEthprice();

        let investPrice = investValue * nativePrice;

        console.log(Number(userTotalInvestment) + Number(investPrice))

        if (Number(userTotalInvestment) + Number(investPrice) < data.minInvest) {
            alert.show(`Minimum Investment is $${data.minInvest}`);
            return;
        }

        if (Number(userTotalInvestment) + Number(investPrice) > data.maxInvest) {
            alert.show(`Maximum Investment is $${data.maxInvest}`);
            return;
        }

        if (networkId === 42 || networkId === 1) {
            let icoContract_ = new EthIcoContract(web3Config.getWeb3(), web3Config.getNetworkId());

            icoContract_.tokenRequest(data.OptionId, investValue.toString(), (txHash) => {
                setinvestLoading(true);
            }, (receipt) => {
                setinvestLoading(false);
                setInvestValue('');
                updateData();
                alert.success("Thank You For Your Investement It Will Refelect Shortly");
            });

        } else {
            icoContract.investWithBnb(data.OptionId, investValue.toString(), (txHash) => {
                setinvestLoading(true);
            }, (receipt) => {
                setinvestLoading(false);
                setInvestValue('');
                updateData();
                alert.success("Thank You For Your Investement");
            });
        }
    }
    var settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function checkInvestValue(value) {
        if (Number(bnb) >= value) {
            setInvestValue(value);
        }

    }
    function setMax() {
        let nativePrice = (networkId === 97 || networkId === 56) ? priceConfig.getBnbprice() : priceConfig.getEthprice();

        let bnbValUsd = bnb * nativePrice;

        if (bnbValUsd + Number(userTotalInvestment) > data.maxInvest) {

            let leftUsd = data.maxInvest - Number(userTotalInvestment);
            let bnbamount = (leftUsd / nativePrice).toFixed(5);
            setInvestValue(bnbamount);

        } else {
            if (bnb > 0.01)
                setInvestValue(bnb - 0.01);
            else
                setInvestValue(0);
        }


    }

    function claimToken(orderId) {
        if (networkId === 42 || networkId === 1) {
            alert.show(`Please connect to Bsc Main net for claiming token`);

        } else {
            icoContract.claimToken(orderId.toString(), (txHash) => {

            }, (receipt) => {
                updateData();
                alert.success("Token Succsfully Sent to Dumper sheild");
            });
        }
    }

    function checkTc() {
        if (tcValue.toLocaleLowerCase().trim() === "confirm") {
            reactLocalStorage.set(data.tokenAddress, true)
            setTcOpen(false);
        }
    }

    return (
        <div class="jwb-tvSbox02">
            <div class="ifoMBox01">
                <div class="ifoSBox01"><img src={data.tokenLogo} onError={addDefaultSrc} /> </div>
                <div class="ifoSBox02">{data.tokenSymbol} <span>{data.tokenName}</span></div>
                <div class="ifoSBox03">Fix Sale<i class="help-circle"><i
                    class="fas fa-question-circle protip"
                    data-tip="Set price, traditional offering"
                    data-pt-position="top" aria-hidden="true"></i></i> <span>{data.tokenDays} Days</span>
                    <span>{data.tokenApy}% Apy</span>
                </div>
            </div>
            <div class="ifoGraph">
                <span style={{ width: `${soldOut}%` }} class="ani-1"></span>
            </div>
            {data.startTime * 1000 < new Date().getTime() ?
                <>
                    <div class="ifo-Title01">Current PreSale ends in</div>

                    <div class="ifo-Counter01">
                        <Countdown date={data.endTime * 1000} intervalDelay={0} precision={3} renderer={renderer} />
                    </div>
                </>
                :
                <>
                    <div class="ifo-Title01">Current PreSale Start in</div>

                    <div class="ifo-Counter01">
                        <Countdown date={data.startTime * 1000} intervalDelay={0} precision={3} renderer={renderer} />
                    </div>
                </>

            }
            {
                !address ? null :
                    <div style={{ textAlign: "center" }}>1 {(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} = {(networkId === 42 || networkId === 1) ? (priceConfig.getEthprice()).toFixed(2) : (priceConfig.getBnbprice()).toFixed(2)} &nbsp; {data.tokenSymbol} = 0.01</div>
            }
            <div class="ifo-Title01"> &ensp; </div>
            {data.startTime * 1000 > new Date().getTime() ?
                <a class="ifo-Btn01" >Pre Sale Not Started Yet</a>
                :
                !address ?
                    <a class="ifo-Btn01" onClick={() => web3Config.connectWallet(0)}>Unlock Wallet</a>
                    : new Date().getTime() > data.endTime * 1000 ?
                        <a class="ifo-Btn01" >Presale Ended</a>
                        : <div class="idoinptBar01">
                            <div class="newIimgBx" ref={ref} >

                                {networkId === constantConfig.bscNetworkId ?
                                    <img src="images/bnb.png" alt="" />
                                    :
                                    <img src="images/pureEth.png" alt="" />
                                }

                                <a href="javascript:void(0);" onClick={() => { setCurrOpen(!currOpen) }} class="ddBTN popupLink" data-id="#ddM03"><i class="fas fa-caret-down"></i></a>

                                <div class="ddContent" id="ddM03" style={{ display: `${currOpen ? 'block' : 'none'}` }}>
                                    {networkId === constantConfig.bscNetworkId ?
                                        <>
                                            {/* <a href="javascript:void(0);" onClick={() => { setCurrOpen(false) }}><img src="images/bnb.png" alt="" /></a> */}
                                            <a href="javascript:void(0);" onClick={() => { alert.info("Select Ethereum Network") }}><img src="images/pureEth.png" alt="" /></a>
                                        </>
                                        :
                                        <>
                                            <a href="javascript:void(0);" onClick={() => { alert.info("Select Binance Network") }}><img src="images/bnb.png" alt="" /></a>
                                            {/* <a href="javascript:void(0);" onClick={() => { setCurrOpen(false) }}><img src="images/pureEth.png" alt="" /></a> */}
                                        </>
                                    }
                                </div>

                            </div>
                            <input class="idoSform01" type="text" value={investValue} onKeyDown={validation.floatOnly} onChange={(e) => { checkInvestValue(e.target.value) }} />

                            {investLoading ?
                                <button class="idoSBTN01" style={{ padding: "0px" }}>
                                    <Loader
                                        type="ThreeDots"
                                        color="#5A5E67"
                                        height={47}
                                        width={47}
                                    />
                                </button>
                                :
                                <button class="idoSBTN01" onClick={invest}>Invest </button>
                            }

                            <div className="inputStextBx ">
                                <span onClick={() => { setMax() }}>MAX</span>
                                <span>[$ {(networkId === constantConfig.bscNetworkId) ? investValue * priceConfig.getBnbprice() : (networkId === 42 || networkId === 1) ? investValue * priceConfig.getEthprice() : 0}]</span>
                            </div>
                        </div>
            }

            <div class="ifoBTN-Bar01 centerFix">
                {data.startTime * 1000 > new Date().getTime() ?
                    <a href="#" className="liveStatusLink"><i className="dotOrange"></i>  Coming Soon </a>
                    : new Date().getTime() > data.endTime * 1000 ?
                        <a href="#" className="liveStatusLink"><i className="dotOrange"></i>  Sale Over </a>
                        :
                        <a href="#" className="liveStatusLink"><i className="dotGreen"></i>  Live  # {data.OptionId < 999 ? '00' + data.OptionId : data.OptionId}</a>
                }
                {/* {data.startTime * 1000 < new Date().getTime() ?
                    <a href="#" className="liveStatusLink"><i className="dotGreen"></i>  Live  # {data.OptionId < 999 ? '00' + data.OptionId : data.OptionId}</a>
                    :  new Date().getTime() > data.endTime * 1000?
                        <a href="#" className="liveStatusLink"><i className="dotOrange"></i>  Sale Over </a>
                    :
                    <a href="#" className="liveStatusLink"><i className="dotOrange"></i>  Coming Soon </a>
                } */}
                <a class="ifoSBTN-02" onClick={() => setIsDetailOpen(!isDetailOpen)}>
                    {!isDetailOpen ?
                        <span class="txn01">Detail <i class="fas fa-chevron-down"></i></span>
                        :
                        <span class="txn02">Hide <i class="fas fa-chevron-up"></i></span>
                    }
                </a>
            </div>

            <div className="cusSlider" style={style}>

                <Slider {...settings} >
                    {userInvestments.length === 0 ?
                        <div>
                            <span class="ifoMoreDTitle01">Presale type<span>Fix Sale</span></span>
                            <span class="ifoMoreDTitle01">Minimum Investment<span>$ {data.minInvest}</span></span>
                            <span class="ifoMoreDTitle01">Maximum Investment<span>$ {data.maxInvest}</span></span>
                            <span class="ifoMoreDTitle01">Launch Time <span>{new Date(data.startTime * 1000).toUTCString()}</span></span>
                            <span class="ifoMoreDTitle01">For sale <span>{validation.convertCurrency(avilableToken)} {data.tokenSymbol}</span></span>
                            <span class="ifoMoreDTitle01">BaseLine purchase price <span>$ 0.01 ({(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} {(networkId === 42 || networkId === 1) ? (0.01 / priceConfig.getEthprice()).toFixed(6) : (0.01 / priceConfig.getBnbprice()).toFixed(6)} )</span></span>
                            <span class="ifoMoreDTitle01">Current Market Price <span>$ {validation.convertCurrency(tokenPrice)} ({(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} {(networkId === 42 || networkId === 1) ? (tokenPrice / priceConfig.getEthprice()).toFixed(6) : (tokenPrice / priceConfig.getBnbprice()).toFixed(6)} )</span></span>
                            <span class="ifoMoreDTitle01">Total raised <span> $ {validation.convertCurrency(totalRaised)} </span></span>
                            <span class="ifoMoreDTitle01">Total raised(% Target) <span> {soldOut} %</span></span>
                            <span class="ifoMoreDTitle01">Proof <span><a href={`${constantConfig.txUrl}/address/${data.vaultAdress}`} target="_blank" rel="noreferrer noopener">{data.tokenAddress.substring(0, 4)}...{data.tokenAddress.substring(38, 42)}</a></span></span>
                            <span class="ifoMoreDTitle01">Staking period<span>{data.tokenDays} days</span></span>
                            <span class="ifoMoreDTitle01">APY<span>{data.tokenApy}%</span></span>
                            <div class="ifoBTN-Bar01 v2 centerFix">
                                <a class="ifoSBTN-03" href={data.whitePaper} target="_blank">
                                    View Project WhitePaper <i class="fas fa-edit"></i>
                                </a>
                                <a class="ifoSBTN-03" href={data.website} target="_blank">
                                    View Project WebSite <i class="fas fa-edit"></i>
                                </a>
                            </div>
                        </div>
                        :
                        userInvestments.map((i, j) => {
                            console.log(userInvestments)
                            return (<div>
                                <span class="ifoMoreDTitle01">Presale type<span>Fix Sale</span></span>
                                <span class="ifoMoreDTitle01">Minimum Investment<span>$ {data.minInvest}</span></span>
                                <span class="ifoMoreDTitle01">Maximum Investment<span>$ {data.maxInvest}</span></span>
                                <span class="ifoMoreDTitle01">Launch Time <span>{new Date(data.startTime * 1000).toUTCString()}</span></span>
                                <span class="ifoMoreDTitle01">For Sale <span>{validation.convertCurrency(avilableToken)} {data.tokenSymbol}</span></span>
                                <span class="ifoMoreDTitle01">BaseLine Purchase Price <span>$ 0.01 ({(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} {(networkId === 42 || networkId === 1) ? (0.01 / priceConfig.getEthprice()).toFixed(6) : (0.01 / priceConfig.getBnbprice()).toFixed(6)} )</span></span>
                                <span class="ifoMoreDTitle01">Current Market Price <span>$ {validation.convertCurrency(tokenPrice)} ({(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} {(networkId === 42 || networkId === 1) ? (tokenPrice / priceConfig.getEthprice()).toFixed(6) : (tokenPrice / priceConfig.getBnbprice()).toFixed(6)} )</span></span>
                                <span class="ifoMoreDTitle01">Total Raised <span> $ {validation.convertCurrency(totalRaised)} </span></span>
                                <span class="ifoMoreDTitle01">Total Raised(% Target) <span> {soldOut} %</span></span>
                                <span class="ifoMoreDTitle01">Proof <span><a href={`${constantConfig.txUrl}/address/${data.tokenAddress}`} target="_blank" rel="noreferrer noopener">{data.tokenAddress.substring(0, 4)}...{data.tokenAddress.substring(38, 42)}</a></span></span>
                                <span class="ifoMoreDTitle01">Staking period<span>{data.tokenDays} days ({Math.ceil(((((((web3Js.utils.toDecimal(userInvestments[j].endDate._hex) + "000") - (new Date()).getTime()) / 1000) / 60) / 60) / 24))} days left)</span></span>
                                <span class="ifoMoreDTitle01">APY<span>{data.tokenApy}%</span></span>
                                <span class="ifoMoreDTitle01">Your Investment<span> ${Number(web3Js.utils.fromWei(userInvestments[j].usdAmount._hex, "gwei")).toFixed(2)} ({(networkId === 42 || networkId === 1) ? "ETH" : "BNB"} {Number(Number(web3Js.utils.fromWei(userInvestments[j].usdAmount._hex, "gwei")) / ((networkId === 42 || networkId === 1) ? (priceConfig.getEthprice()) : (priceConfig.getBnbprice()))).toFixed(6)})</span></span>
                                <span class="ifoMoreDTitle01">Receivable Tokens<span>{validation.convertCurrency(web3Js.utils.fromWei(userInvestments[j].tokenAmount._hex))} {data.tokenSymbol}</span></span>
                                <span class="ifoMoreDTitle01">Reward Tokens<span>{validation.convertCurrency(web3Js.utils.fromWei(userInvestments[j].reward._hex))} {data.tokenSymbol}</span></span>
                                <span class="ifoMoreDTitle01">Total Receivable Tokens<span>{validation.convertCurrency(Number(web3Js.utils.fromWei(userInvestments[j].tokenAmount._hex)) + Number(web3Js.utils.fromWei(userInvestments[j].reward._hex)))} {data.tokenSymbol}</span></span>
                                <span class="ifoMoreDTitle01">Final Value after APY<span>$ {validation.convertCurrency((Number(web3Js.utils.fromWei(userInvestments[j].tokenAmount._hex)) + Number(web3Js.utils.fromWei(userInvestments[j].reward._hex))) * tokenPrice)}</span></span>


                                {new Date().getTime() > Number(web3Js.utils.toDecimal(userInvestments[j].endDate._hex)) * 1000
                                    && !userInvestments[j].fullfilled ?
                                    !claimLoading ?
                                        <>
                                            <a class="ifo-Btn01" onClick={() => claimToken(web3Js.utils.hexToNumber(userOrderId[j]._hex))} style={{ marginBottom: '0px', backgroundColor: '#8be05a' }} >Claim Token</a>
                                            <span class="ifoMoreDTitle01">&nbsp;&nbsp;Your tokens will secure under the <a href='https://shield.pdo.finance' target="_blank" style={{ color: 'blue' }}> &nbsp;Dumper Shield</a>
                                            </span>
                                        </>
                                        :
                                        <>
                                            <a class="ifo-Btn01" style={{ marginBottom: '0px', backgroundColor: '#8be05a' }} >Claiming Tokens ..</a>
                                            <span class="ifoMoreDTitle01">&nbsp;&nbsp;Your tokens will secure under the <a href='https://shield.pdo.finance' target="_blank" style={{ color: 'blue' }}> &nbsp;Dumper Shield</a>
                                            </span>
                                        </>

                                    : null
                                }

                                <div class="ifoBTN-Bar01 v2 centerFix">
                                    <a class="ifoSBTN-03" href={data.whitePaper} target="_blank">
                                        View Project WhitePaper <i class="fas fa-edit"></i>
                                    </a>
                                    <a class="ifoSBTN-03" href={data.website} target="_blank">
                                        View Project WebSite <i class="fas fa-edit"></i>
                                    </a>
                                </div>

                                {/* <a class="ifo-Btn01" >Pre Sale Not Started Yet</a> */}
                            </div>);
                        })}

                </Slider>
            </div>
            <div class="ifoAlertPopup" style={{ display: `${tcOpen ? '' : 'none'}` }}>
                <div class="apPopupContainer">
                    <a href="#" class="apCloseBTN ani-1" onClick={() => setTcOpen(false)}> <img src="images/xBTN.png" /> </a>
                    <div class="appopText01">
                        <p><i class="fas fa-info-circle"></i> IMPORTANT</p>
                        All your {data.tokenSymbol} tokens will be LOCKED FOREVER with NO ability to send, sell, swap, or claim until you successfully complete your KYC/AML. If you are from a regulated country like the USA, you will also need to complete accreditation compliance with Regulation D 506(c) otherwise you will be unable to engage with your SMART tokens. You can avoid that risk by clicking <a href={`https://www.jointer.io/email-verification/${address}`} target="_blank">here</a> to start your KYC/AML before investing, otherwise type the word <span style={{ color: 'white' }} class="white">CONFIRM</span> to show that you understand and agree to the above terms.
                    </div>
                    <div class="appopBTNbar">
                        <input type="text" style={{ color: '#ffffff' }} value={tcValue} placeholder="Type here CONFIRM" onChange={(e) => { setTcValue(e.target.value) }} />
                        <button class="okBTN" onClick={checkTc} >OK</button>
                    </div>
                </div>
            </div>
            <ReactTooltip effect="solid" className="myTip" />
        </div>

    )


}