

const data = {}

data.ethNetworkId = 1;
data.bscNetworkId = 56;

data.icoAddress = '0xff21984f747ee8573d67a1028ea464dc171e43b5';

data.ethAddress = "0xa7ded9f4ebec00cbf445fd864f7c13646736ea3e";


data.prepath = "";


data.networkMsg = "Please select Bsc Main Network"

data.allowedNetwork = [1,56];


data.bscRpc = 'https://bsc-dataseed.binance.org/';

data.txUrl = "https://bscscan.com"

data.tokenData = [
    {
        tokenName:"DEGENSWAP",
        tokenSymbol:"DEGEN",
        tokenAddress:"0x1Eea643fc6C0B4D253621839986fc566Fce40704",
        tokenLogo:require('../assets/images/degen.png'),
        tokenDays:90,
        tokenApy:413.5,
        OptionId:1,
        whitePaper:'https://docsend.com/view/ftkbhijkqtpwu4up',
        website:'https://degenswap.io/',
        minInvest:1,
        maxInvest:1000,
        startTime:1636977600,
        endTime:1638273599,
        forSale:"100,979,453",
        vaultAdress:"0x8ad0de83c61f21c4fa371ff57eb5fd407b2396fe",
        priceApi:"https://api.dynamicswap.exchange/degenPrice"

    },

    // {
    //     tokenName:"Smart Governance Token V2",
    //     tokenSymbol:"SMART",
    //     tokenAddress:"0x653481aAE05AFe68330af8214Fba9f3BEf523422",
    //     tokenDays:90,
    //     tokenApy:413.2,
    //     OptionId:2,
    //     whitePaper:'https://docsend.com/view/24h32qhisbiunjqk',
    //     website:'https://smartswap.exchange/',
    //     minInvest:20,
    //     maxInvest:2000,
    //     startTime:1618395586,
    //     endTime:1619864386
    // }
]

module.exports = data;