import React from "react";
import web3Config from "../config/web3Config";
import constantConfig from "../config/constantConfig";
import { useAlert } from 'react-alert'

const { useState, useEffect } = React;


export default function Header() {
    const alert = useAlert()

    const [address, setAddress] = useState(web3Config.getAddress());
    const [networkId, setNetworkId] = useState(web3Config.getNetworkId());

    function updateData() {
        if(!constantConfig.allowedNetwork.includes(web3Config.getNetworkId())){
            alert.error(constantConfig.networkMsg);
            return 
        }
        setAddress(web3Config.getAddress());
        setNetworkId(web3Config.getNetworkId());
    }

    useEffect(() => {
        web3Config.on("WEB3_CONNECTED", updateData);
    })


    return (

        <header className="bsc-Header ani-1">
            <div className="bscHeadContainer">
                <a href="https://dynamicswap.exchange/" className="headLogo"><img src="images/bscLogo-v2.png" /></a>

            
                <div className="headBar02" >
                    {networkId === constantConfig.ethNetworkId ? <a className="headBTN01"><i className="fab fa-ethereum"></i></a> : null}
                    {networkId === constantConfig.bscNetworkId ? <a className="headBTN01 img"> <img src="images/bsc-icon.png" /></a> : null}

                    {address === null ?
                        <a className="headBTN02" onClick={() => web3Config.connectWallet(0)}>Connect Wallet</a>
                        :
                        <a className="headBTN02" >{address.substring(0, 4) + "..." + address.substring(38, 42)}</a>
                    }

                    <a href="javascript:void(0);" className="headIndiBTN"></a> 
                </div>


            </div>


        </header>


    )


}

