import React, { PureComponent } from 'react'

import {
  tokenData
} from '../config/constantConfig'

import IcoBox from '../component/icoBox'
import priceConfig from '../helper/pricing'
import ReactTooltip from 'react-tooltip'

export default class Home extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      web3: null,
      bonusData: []
    }
  }

  componentDidMount () {
    priceConfig.fetchEthPrice();
    priceConfig.fetchBnbPrice();
  }

  


  componentWillUnmount () {}
  
  launchIco(){
   // notificationConfig.info("coming soon!!");
  }

  
  render () {
    return (
      <section class='binaceSwap'>
        <div class='container-Grid'>
          <div class='binanceStitle01'> Decentralized Cross-chain Presale </div>
          <div class='binanceStitle02'>
            Send your ETH or BNB for ground floor access to new tokens with APY rewards
            <i class='help-circle'>
              <i
                class='fas fa-question-circle protip'
                data-tip="Typically presale funds are utilized to support,
                develop, market and listings. Investors is the 
                presale must complete KYC / AML (and maybe
                accreditation check) in order to claim the tokens
                at the end of the staking period"
                data-pt-position='top'
                aria-hidden='true'
              ></i>
            </i>
          </div>
          {/* <div class='jwb-searchMBox'>
            <div class='jwb-searchLbox'>
              <div class='jwb-SLbox-title01'>
                <i class='fas fa-search'></i> Search by
              </div>
              <div class='jwb-SLbox-inputBox'>
                <input
                  type='text'
                  placeholder='token symbol, contract address, APY...'
                  readOnly={true}
                />
              </div>
              <a
                href='javascript:void(0);'
                class='jwbFilterIcon'
                data-toggle='n-collapse'
                data-target='#searchFilter01'
                aria-expanded='false'
                aria-controls='searchFilter01'
              >
                <i class='fas fa-filter'></i>
              </a>
            </div>
            <div class='jwb-searchRbox'>
              <a href='javascript:void(0);' class='jwbGreenBTN ani-1' onClick={this.launchIco.bind(this)}>
                <i class='jwbTrophyIco'></i> <div className="rgdevComingL">Coming Soon</div> LAUNCH YOUR PRESALE
              </a>
            </div>
            <div class='n-collapse' id='searchFilter01'>
              <div class='jwb-searchCMbox'></div>
            </div>
          </div> */}
          <div class="jwb-TokenViewMbox alignItemFlexStart">
              {tokenData.map((i,j) => {
                return <IcoBox data={tokenData[j]} ></IcoBox>
              })}
          </div>
        </div>
        <ReactTooltip effect="solid" className="myTip" />
      </section>
    )
  }
}
