import React from "react";
import web3Config from "../config/web3Config";
import { useAlert } from 'react-alert'

const { useState, useEffect, useRef } = React;



export default function Footer(props) {

    const [address, setAddress] = useState(web3Config.getAddress());

    const alert = useAlert()


    function checkWallet() {

        if(address === null){
            alert.show("Connect Your wallet");
        }else
            window.open("https://www.jointer.io/email-verification/"+address, '_blank').focus();
    }

    function updateData() {
        setAddress(web3Config.getAddress());
    }

    useEffect(() => {
        web3Config.on("WEB3_CONNECTED", updateData);
    }, [])


    return (
        <footer className="binanceSSFooter">
            <div className="jrlogo">
                <img src="images/fighter-icon.png" />
            </div>
            <div className="bscFooterCbox">
                <div className="bscFLink01B">
                    <a href="javascript:void(0);" onClick={checkWallet} className="orangeColor">Get Whitelisted (KYC/AML)</a>
                    {/* <a href="https://elementzero.network/bscswap_upgrade/" >Upgrade to v2</a> |
                    <a href="javascript:void(0);" >LaunchField v1</a> |
                    <a href="javascript:void(0);">Voting </a> | 
                    <a href="https://github.com/bscswap/contracts" target="_blank"> Github</a> | 
                    <a href="https://bscswapprotocol.medium.com/" target="_blank">Medium</a> | 
                    <a href="https://t.me/bscswapgroup" target="_blank">Telegram</a> | 
                    <a href="https://twitter.com/bSWAPDAO" target="_blank">Twitter</a> | 
                    <a href="https://discord.com/invite/mCZSA3w" target="_blank">Discord</a>  */}
                </div>
                {/* <div className="bscFLink02B">
                    <a href="javascript:void(0);">Powered by Atom Foundation:</a> <a href="https://jointer.io"
                        target="_blank">Jointer.io</a> | <a
                            href="https://smartswap.exchange/"
                            target="_blank" >SmartSwap.exchange</a> |
                    <a href="https://smartswap.exchange/"
                        target="_blank">ZERO/1 </a> |
                    <a href="https://degenswap.io/" target="_blank">DegenSwap.io </a> |
                    <a href="https://elementzero.network/" target="_blank">ElementZero.network</a>
                    | <a href="javascript:void(0);">Packey.insure</a>
                    | <a href="javascript:void(0);">Mass.investments</a>
                </div> */}
            </div>
            <div className="bnlogo">
                <a href="#" className="eth-logo"><img src="images/etherLOGO.png" alt="" />    </a>
                <a href="#" className="bnb-logo" ><img src="images/binance-logo.png" /></a>
            </div>
        </footer>

    )


}