
import web3Js from 'web3';
import {
  EventEmitter
} from "events";
import dispatcher from "../dispatcher";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {  providers  } from "ethers";


const web3Provider = new WalletConnectProvider({
    infuraId: "81e7625588484d45a3a3be76abbb2030", // Required
});

class Web3Config extends EventEmitter {


    constructor() {
        super();
        this.web3 = null;
        this.netWorkId = 0;
        this.address = null;
    }
    
    //0 = metamask
    //1 = walletConnect 
    async connectWallet(type){
        let web3 ;
        if(type === 0){
            web3 = new providers.Web3Provider(window.ethereum);
            if (window.ethereum) {
                
                try {
                    await window.ethereum.enable();
                    this.web3 = web3;
                    this.checkAccount();
                } catch (error) {
                
                }
            }
            else if (window.web3) {
                web3 = new providers.Web3Provider(window.currentProvider);
                this.web3 = web3;
                this.checkAccount();
            }
        
        }else if(type === 1){
            await web3Provider.enable();
            const web3 = new providers.Web3Provider(web3Provider);
            this.web3 = web3;
            this.checkAccount();
        
            // signer.sendTransaction(tx).then(result => {
            //     // Returns transaction id (hash)
            //     console.log(result);
            // }).catch(error => {
            //     // Error returned when rejected
            //     console.error(error);
            //   });

            

             //console.log(await ethers.getBalance("0x02F024e0882B310c6734703AB9066EdD3a10C6e0"))

        }        
    }

    async checkAccount(){
        let web3 = this.web3;
        let address = await web3.listAccounts();
        let netWorkId = await web3.getNetwork();
        this.netWorkId = netWorkId.chainId;
        this.address = address[0];
        this.emit("WEB3_CONNECTED");
    
    }


    async getNativeBalance(address, setter) {
        let a = await this.web3.getBalance(address);
        setter(web3Js.utils.fromWei(a._hex))
        return web3Js.utils.fromWei(a._hex);
    }

    getAddress(){
        return this.address;
    }


    getNetworkId(){
        return this.netWorkId;
    }

    getWeb3(){
        return this.web3    ;
    }

    handleActions(action) {
        switch (action.type) { }
    }

}


const web3Config = new Web3Config();
dispatcher.register(web3Config.handleActions.bind(web3Config));
export default web3Config;