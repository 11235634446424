import React from 'react';
import { positions, Provider } from "react-alert";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import AlertTemplate from "react-alert-template-basic";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import Footer from './component/footer';
import Header from './component/header';
import Home from './pages/home';

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT
};


const DefaultRouter = () => ( 
  <Provider template={AlertTemplate} {...options}>
    <main id="main" className="darkBG">
      <Router basename={''}>
        <Header></Header>
        <Switch>
          <Route path="/" component={Home}/>
        </Switch>
      </Router >
      <Footer></Footer>
    </main>
  </Provider>
)

export default DefaultRouter; 