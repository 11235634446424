import {
    EventEmitter
} from "events";
import web3Js from 'web3';
import { ethers } from 'ethers';

import bscAbi from "../abis/bsc.json";
import tokenAbi from "../abis/tokenAbi.json"

import constantConfig from "../config/constantConfig";


class IcoContract extends EventEmitter {

    constructor(web3,networkId) {
        super();
        this.web3 = web3;
        this.networkId = networkId;
        this.icoAddress = constantConfig.icoAddress;
        this.icoInstance = new ethers.Contract(
            this.icoAddress,
            bscAbi,
            web3.getSigner(0)
        );
        
    }

    static async getTokenAmount(OptionId,setter){
        let web3 = new ethers.providers.JsonRpcProvider(constantConfig.bscRpc);
        let icoInstance = new ethers.Contract(
            constantConfig.icoAddress,
            bscAbi,
            web3
        );
        let a  = await icoInstance.options(OptionId);
        let vault = a.vault;
        let token = a.token;
        let tokenContract = new ethers.Contract(
            token,
            tokenAbi,
            web3
        );
        let amoutn  = await tokenContract.balanceOf(vault);
        amoutn = web3Js.utils.fromWei(amoutn._hex);

        const wei = 1;
        let rate = ((wei*90*413.5)/(100*365))+wei;
        setter((amoutn*wei/rate).toFixed(0))

    }
    
    static async getSoldOut(OptionId,setter){
        let web3 = new ethers.providers.JsonRpcProvider(constantConfig.bscRpc);
        let icoInstance = new ethers.Contract(
            constantConfig.icoAddress,
            bscAbi,
            web3
        );
        
        let a  = await icoInstance.getSoldOutRatio(OptionId);
        let b  = await icoInstance.totalRaised(OptionId);
        setter(web3Js.utils.hexToNumberString(a._hex)/100,web3Js.utils.fromWei(b._hex,"Gwei"))
        return [web3Js.utils.hexToNumberString(a._hex)/100,web3Js.utils.fromWei(b._hex,"Gwei")];
    }

    
    async getUserOrderOptionWise(userAddress,OptionId,setter){
        let web3 = new ethers.providers.JsonRpcProvider(constantConfig.bscRpc);
        let icoInstance = new ethers.Contract(
            constantConfig.icoAddress,
            bscAbi,
            web3
        );
        let a  = await icoInstance.getUserOrderOptionWiseByData(userAddress,OptionId);
        let orderId  = await icoInstance.getUserOrderOptionWiseById(userAddress,OptionId);
        setter(a,orderId);
    }

    
    async getInvestOptionWise(userAddress,OptionId,setter){
        let web3 = new ethers.providers.JsonRpcProvider(constantConfig.bscRpc);
        let icoInstance = new ethers.Contract(
            constantConfig.icoAddress,
            bscAbi,
            web3
        );
        let a  = await icoInstance.optionWiseInvesment(userAddress,OptionId);
        setter(web3Js.utils.fromWei(a._hex,"Gwei"));
    }

    


    async getInvestFee(){
        let gasPrice = "0";
        if (this.networkId === constantConfig.bscNetworkId)
            gasPrice = "20";
        else{
            const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json');
            const json = await response.json();
            gasPrice = (json.fast/10).toString();
        }
        return 635285*web3Js.utils.fromWei(web3Js.utils.toWei(gasPrice,"gwei"));
    }



    async investWithBnb(optionId,value,txCb,receiptCb){
        let a = await this.icoInstance.populateTransaction.stakeWithBnb(optionId);
        value = web3Js.utils.toWei(value);
        this.sendTransaction(a.data,value,"635285",txCb,receiptCb,this.icoAddress);
    }


    async claimToken(orderId,txCb,receiptCb){
        let a = await this.icoInstance.populateTransaction.unstake(orderId);
        this.sendTransaction(a.data,0,"800000",txCb,receiptCb,this.icoAddress);
    }


    async sendTransaction(payload,value,gasLimit,txCb,receiptCb,to){
        let gasPrice = "0";

        if (this.networkId === constantConfig.bscNetworkId)
            gasPrice = "20";
        else{
            const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json');
            const json = await response.json();
            gasPrice = (json.fast/10).toString();
        }

        const tx = {
            to: to, 
            data: payload, 
            gasPrice: web3Js.utils.toHex(web3Js.utils.toWei(gasPrice,"gwei")), 
            gasLimit:web3Js.utils.toHex(gasLimit),
            value: web3Js.utils.toHex(value)
        };

        this.web3.getSigner(0).sendTransaction(tx).then(result => {
            txCb(result.hash)
            result.wait().then(async (receipt)=>{
                receiptCb(receipt);
            })
        }).catch(error => {
            console.log(error);
        });
    }

    

    


    handleActions(action) { 
        switch (action.type) { }
    }

}





export default IcoContract;